export const GET_TERMINAL_LIST = '[terminal] GET_TERMINAL_LIST'
export const GET_TERMINAL_CURRENT_ID = '[terminal] GET_TERMINAL_CURRENT_ID'
export const GET_TERMINAL_CURRENT_ID_SLUG =
  '[terminal] GET_TERMINAL_CURRENT_ID_SLUG'
export const GET_TERMINAL_CURRENT_REQUIRE_INN =
  '[terminal] GET_TERMINAL_REQUIRE_INN'
export const GET_IS_INCOMPLETE_PARKING = '[terminal] GET_IS_INCOMPLETE_PARKING'
export const GET_IS_ALLOWED_MANUAL_TS = '[terminal] GET_IS_ALLOWED_MANUAL_TS'
export const GET_HAS_BLACKLIST = '[terminal] GET_HAS_BLACKLIST'
export const GET_HAS_WHITELIST = '[terminal] GET_HAS_WHITELIST'
export const GET_IS_USE_COMPLEX_PARKING_STATUSES =
  '[terminal] GET_IS_USE_COMPLEX_PARKING_STATUSES'
export const GET_LATE_INTERVAL = '[terminal] GET_LATE_INTERVAL'
export const GET_ARRIVAL_PREFIX = '[terminal] GET_ARRIVAL_PREFIX'
export const GET_TIMEZONE_OFFSET = '[terminal] GET_TIMEZONE_OFFSET'

export const FETCH_TERMINALS = '[terminal] FETCH_TERMINALS'
export const SET_TERMINALS = '[terminal] SET_TERMINALS'
export const SET_TERMINAL = '[terminal] SET_TERMINAL'
export const SET_TERMINAL_SLUG = '[terminal] SET_TERMINAL_SLUG'
export const SET_TIMEZONE_OFFSET = '[terminal] SET_TIMEZONE_OFFSET'

export const GET_DRAWER = '[drawer] GET_DRAWER'
export const SET_DRAWER = '[drawer] SET_DRAWER'
export const SET_DRAWERS_CLEAN = '[drawer] SET_DRAWERS_CLEAN'

export const GET_UPDATE_ACTION = '[drawer] GET_UPDATE_ACTION'
export const SET_UPDATE_ACTION = '[drawer] SET_UPDATE_ACTION'

export const GET_DIALOG = '[dialog] GET_DIALOG'
export const SET_DIALOG = '[dialog] SET_DIALOG'

export const GET_EDITOR_TEXT = '[settings] GET_EDITOR_TEXT'
export const SET_EDITOR_TEXT = '[settings] SET_EDITOR_TEXT'
export const FETCH_EDITOR_TEXT = '[settings] FETCH_EDITOR_TEXT'

export const GET_IS_AUTH = '[auth] GET_IS_AUTH'
export const GET_IS_AUTH_LOADING = '[auth] GET_IS_AUTH_LOADING'
export const SET_IS_AUTH_LOADING = '[auth] SET_IS_AUTH_LOADING'
export const GET_ROLE = '[auth] GET_ROLE'
export const FETCH_AUTH = '[auth] FETCH_AUTH'
export const FETCH_REGISTER = '[auth] FETCH_REGISTER'
export const SET_LOGIN_PARAMS = '[auth] SET_LOGIN_PARAMS'
export const AUTH_LOGOUT = '[auth] AUTH_LOGOUT'
export const SET_LOGOUT = '[auth] SET_LOGOUT'

export const GET_REQUEST_STATUS = '[supervisor] GET_REQUEST_STATUS'
export const GET_SUPERVISOR_QUOTAS_LINES =
  '[supervisor] GET_SUPERVISOR_QUOTAS_LINES'
export const GET_SUPERVISOR_QUOTAS_FOR_TABLE =
  '[supervisor] GET_SUPERVISOR_QUOTAS_FOR_TABLE'
export const GET_SUPERVISOR_STATISTIC_TABLE =
  '[supervisor] GET_SUPERVISOR_STATISTIC_TABLE'
export const GET_LOADINGS_SUPERVISOR_STATISTIC =
  '[supervisor] GET_LOADINGS_SUPERVISOR_STATISTIC'
export const GET_LOADINGS_CALL_DRIVER = '[supervisor] GET_LOADINGS_CALL_DRIVER'
export const GET_HISTORY_QUOTAS_SUPERVISOR =
  '[supervisor] GET_HISTORY_QUOTAS_SUPERVISOR'
export const GET_DATE_INTERVAL_QUOTAS_SUPERVISOR =
  '[supervisor] GET_DATE_INTERVAL_QUOTAS_SUPERVISOR'

export const FETCH_SUPERVISOR_QUOTAS_LINES =
  '[supervisor] FETCH_SUPERVISOR_QUOTAS_LINES'
export const SET_REQUEST_STATUS = '[supervisor] SET_REQUEST_STATUS'
export const SET_HISTORY_SUPERVISOR_QUOTA_FROM_SERVER =
  '[supervisor] SET_HISTORY_SUPERVISOR_QUOTA_FROM_SERVER'
export const SET_HISTORY_SUPERVISOR_QUOTA =
  '[supervisor] SET_HISTORY_SUPERVISOR_QUOTA'
export const SET_SUPERVISOR_QUOTA_NEW_DAY =
  '[supervisor] SET_SUPERVISOR_QUOTA_NEW_DAY'
export const SET_SUPERVISOR_QUOTA_CHANGE_ROW_BARS =
  '[supervisor] SET_SUPERVISOR_QUOTA_CHANGE_ROW_BARS'
export const SET_SUPERVISOR_STATISTIC_TABLE =
  '[supervisor] SET_SUPERVISOR_STATISTIC_TABLE'
export const SET_LOADINGS_CALL_DRIVER = '[supervisor] SET_LOADINGS_CALL_DRIVER'
export const SET_LOADINGS_SUPERVISOR_STATISTIC =
  '[supervisor] SET_LOADINGS_SUPERVISOR_STATISTIC'

export const SET_SUPERVISOR_QUOTAS_LINES =
  '[supervisor] SET_SUPERVISOR_QUOTAS_LINES'
export const FETCH_SUPERVISOR_QUOTA = '[supervisor] FETCH_SUPERVISOR_QUOTA'
export const POST_SUPERVISOR_QUOTA = '[supervisor] POST_SUPERVISOR_QUOTA'
export const POST_SUPERVISOR_QUOTAS_LINES =
  '[supervisor] POST_SUPERVISOR_QUOTAS_LINES'
export const DELETE_SUPERVISOR_QUOTAS_LINE =
  '[supervisor] DELETE_SUPERVISOR_QUOTAS_LINE'
export const PUT_SUPERVISOR_QUOTA = '[supervisor] PUT_SUPERVISOR_QUOTA'
export const DELETE_SUPERVISOR_QUOTA = '[supervisor] DELETE_SUPERVISOR_QUOTA'
export const FETCH_SUPERVISOR_STATISTIC_TABLE =
  '[supervisor] FETCH_SUPERVISOR_STATISTIC_TABLE'

export const FETCH_SUPERVISOR_ARCHIVE = '[supervisor] FETCH_SUPERVISOR_ARCHIVE'
export const GET_SUPERVISOR_ARCHIVE = '[supervisor] GET_SUPERVISOR_ARCHIVE'
export const SET_SUPERVISOR_ARCHIVE = '[supervisor] SET_SUPERVISOR_ARCHIVE'
export const SAVE_SUPERVISOR_SAMPLE = '[supervisor] SAVE_SUPERVISOR_SAMPLE'
export const DELETE_SUPERVISOR_SAMPLE = '[supervisor] DELETE_SUPERVISOR_SAMPLE'
export const FETCH_SUPERVISOR_SAMPLES = '[supervisor] FETCH_SUPERVISOR_SAMPLES'
export const GET_SUPERVISOR_SAMPLES = '[supervisor] GET_SUPERVISOR_SAMPLES'
export const SET_SUPERVISOR_SAMPLES = '[supervisor] SET_SUPERVISOR_SAMPLES'

export const DR_GET_CULTURES = '[drivers] GET_CULTURES'
export const DR_FETCH_CULTURES = '[drivers] FETCH_CULTURES'
export const DR_FETCH_AUTOCOMLETE_DATA = '[drivers] DR_FETCH_AUTOCOMLETE_DATA'
export const DR_FETCH_AUTOCOMLETE_SUPPLIER =
  '[drivers] DR_FETCH_AUTOCOMLETE_SUPPLIER'
export const DR_SET_CULTURES = '[drivers] SET_CULTURES'
export const DR_SET_FULL_FORM_WITH_AUTO = '[drivers] DR_SET_FULL_FORM_WITH_AUTO'

export const DR_REMOVE_TIMESLOT = '[drivers] DR_REMOVE_TIMESLOT'

export const DR_GET_TIMESLOT_LIST = '[drivers] DR_GET_TIMESLOT_LIST'
export const DR_GET_AUTOCOMLETE_DATA = '[drivers] DR_GET_AUTOCOMLETE_DATA'
export const DR_GET_AUTOCOMLETE_SUPPLIER =
  '[drivers] DR_GET_AUTOCOMLETE_SUPPLIER'
export const DR_FETCH_TIMESLOT_LIST = '[drivers] DR_FETCH_TIMESLOT_LIST'
export const DR_UPDATE_TIMESLOT_LIST = '[drivers] DR_UPDATE_TIMESLOT_LIST'
export const DR_GET_UNLOAD_ID = '[drivers] DR_GET_UNLOAD_ID'
export const DR_SET_TIMESLOT_LIST = '[drivers] DR_SET_TIMESLOT_LIST'
export const DR_GET_FULL_FORM_WITH_AUTO = '[drivers] DR_GET_FULL_FORM_WITH_AUTO'
export const DR_GET_TIMESLOT_PAGINATION = '[drivers] DR_GET_TIMESLOT_PAGINATION'
export const DR_SET_TIMESLOT_PAGINATION = '[drivers] DR_SET_TIMESLOT_PAGINATION'

export const DR_GET_TERMINALS = '[drivers] GET_TERMINALS'
export const DR_FETCH_TERMINALS = '[drivers] FETCH_TERMINALS'
export const DR_SET_TERMINALS = '[drivers] SET_TERMINALS'

export const DR_GET_SUPPLIERS = '[drivers] DR_GET_SUPPLIERS'
export const DR_GET_USER_PROFILE = '[drivers] DR_GET_USER_PROFILE'
export const DR_FETCH_USER_PROFILE = '[drivers] DR_FETCH_USER_PROFILE'
export const DR_FETCH_SUPPLIERS = '[drivers] FETCH_SUPPLIERS'
export const DR_SET_SUPPLIERS = '[drivers] SET_SUPPLIERS'
export const DR_SET_CURRENT_TIME = '[drivers] DR_SET_CURRENT_TIME'
export const DR_SET_USER_PROFILE = '[drivers] DR_SET_USER_PROFILE'

export const DR_FETCH_COMMON_SUPPLIERS_CHECK_INN =
  '[drivers] DR_FETCH_COMMON_SUPPLIERS_CHECK_INN'
export const DR_GET_LOADINGS = '[drivers] DR_GET_LOADINGS'
export const DR_SET_LOADINGS = '[drivers] DR_SET_LOADINGS'
export const DR_SET_AUTOCOMPLETE_DATA = '[drivers] DR_SET_AUTOCOMPLETE_DATA'
export const DR_SET_AUTOCOMPLETE_SUPPLIER =
  '[drivers] DR_SET_AUTOCOMPLETE_SUPPLIER'
export const DR_GET_SLOAT_PRICE_DATA = '[drivers] DR_GET_SLOAT_PRICE_DATA'
export const DR_UPDATE_SLOAT_PRICE_DATA = '[drivers] DR_UPDATE_SLOAT_PRICE_DATA'
export const DR_SET_SLOAT_PRICE_DATA = '[drivers] DR_SET_SLOAT_PRICE_DATA'
export const DR_UPDATE_SLOAT_PRICE_DATA_STATUS =
  '[drivers] DR_UPDATE_SLOAT_PRICE_DATA_STATUS'
export const DR_UPDATE_SLOAT_PRICE_DATA_SATUS =
  '[drivers] DR_UPDATE_SLOAT_PRICE_DATA_SATUS'
export const DR_GET_CURRENT_TIME = '[drivers] DR_GET_CURRENT_TIME'
export const DR_GET_RUN_TIMER = '[drivers] DR_GET_RUN_TIMER'
export const DR_UPDATE_CURRENT_TIME = '[drivers] DR_UPDATE_CURRENT_TIME'
export const DR_UPDATE_TIMESLOTS_SUBMIT = '[drivers] DR_UPDATE_TIMESLOTS_SUBMIT'
export const DR_UPDATE_RUN_TIMER = '[drivers] DR_UPDATE_RUN_TIMER'
export const DR_SET_RUN_TIMER = '[drivers] DR_SET_RUN_TIMER'
export const DR_UPDATE_SETUP_TIME = '[drivers] DR_UPDATE_SETUP_TIME'
export const DR_GET_SETUP_TIME = '[drivers] DR_GET_SETUP_TIME'
export const DR_SET_CHOSEN_UNLOAD_ID = '[drivers] DR_SET_CHOSEN_UNLOAD_ID'
export const DR_CLEAN_FULL_FORM_WITH_AUTO =
  '[drivers] DR_CLEAN_FULL_FORM_WITH_AUTO'

export const DR_POST_TIME_REQUEST = '[drivers] DR_POST_TIME_REQUEST'
export const DR_POST_AUTOCOMPLETE_DATA = '[drivers] DR_POST_AUTOCOMPLETE_DATA'
export const DR_POST_TIMESLOTS_SUBMIT = '[drivers] DR_POST_TIMESLOTS_SUBMIT'

export const DR_UPDATE_FULL_FORM_WITH_AUTO =
  '[drivers] DR_UPDATE_FULL_FORM_WITH_AUTO'

export const GET_LOADINGS_TABLE_STATIC_DATA =
  '[tableStatic] GET_LOADINGS_TABLE_STATIC_DATA'
export const GET_LOADINGS_GET_UNLOADS_LIST =
  '[tableStatic] GET_LOADINGS_GET_UNLOADS_LIST'
export const GET_TABLE_STATIC_DATA = '[tableStatic] GET_TABLE_STATIC_DATA'
export const GET_UNLOAD_ID = '[tableStatic] GET_UNLOAD_ID'
export const GET_UNLOADS_LIST = '[tableStatic] GET_UNLOADS_LIST'

export const SET_TABLE_STATIC_DATA = '[tableStatic] SET_TABLE_STATIC_DATA'
export const SET_UNLOAD_ID = '[tableStatic] SET_UNLOAD_ID'
export const UPDATE_UNLOAD_LIST = '[tableStatic] UPDATE_UNLOAD_LIST'
export const SET_UNLOADS_LIST = '[tableStatic] SET_UNLOADS_LIST'
export const FETCH_TABLE_STATIC_DATA = '[tableStatic] FETCH_TABLE_STATIC_DATA'
export const FETCH_UNLOADS_LIST = '[tableStatic] FETCH_UNLOADS_LIST'
export const SET_LOADINGS_TABLE_STATIC_DATA =
  '[tableStatic] SET_LOADINGS_TABLE_STATIC_DATA'
export const SET_LOADINGS_UNLOADS_LIST =
  '[tableStatic] SET_LOADINGS_UNLOADS_LIST'

// определение мобильной версии
export const GET_IS_MOBILE = '[screenSize] GET_IS_MOBILE'
export const SET_IS_MOBILE = '[screenSize] SET_IS_MOBILE'
