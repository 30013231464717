import { getPercentNum } from '@/core'

const progressColors = {
  filling: '#4CA7D8',
  filled: '#67C23A',
}

export const getProgressBarParams = quota => {
  const result = getPercentNum(
    Number(quota.trucks_allowed_count - quota.future_real),
    Number(quota.trucks_allowed_count),
  )

  if (result >= 100) {
    return {
      percentage: 100,
      color: progressColors.filled,
    }
  }

  if (result > 0 && result < 10) {
    return {
      percentage: 10,
      color: progressColors.filling,
    }
  }

  return {
    percentage: result,
    color: progressColors.filling,
  }
}
